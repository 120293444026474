import React from "react";
import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
    @font-face {
      font-family: 'Roboto Flex';
      font-style: italic;
      src: url('/fonts/RobotoFlex.woff2') format('woff2') tech('variations'),
      url('/fonts/RobotoFlex.woff2') format('woff2-variations');
      font-weight: 100 1000;
      font-stretch: 25% 151%;
      font-display: swap;
    }
  `}
  />
);

export default Fonts;
