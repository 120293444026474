import React from "react";
import { Box } from "@chakra-ui/react";

// @ts-ignore
export default ({ children }) => {
  return (
    <>
      <Box as="main">{children}</Box>
    </>
  );
};
