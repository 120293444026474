import { defineStyle, extendTheme } from "@chakra-ui/react";

const Button = defineStyle({
  baseStyle: {
    fontWeight: "bold",
    textTransform: "uppercase",
    borderRadius: "full",
  },
  sizes: {
    xl: {
      px: "6",
      py: "4",
      fontSize: "xl",
    },
  },
  variants: {
    outline: {
      border: "1px solid",
      borderColor: "primary",
      color: "primary",
      _hover: {
        backgroundColor: "transparent",
      },
    },
    outlineDark: {
      border: "1px solid",
      borderColor: "blackAlpha.800",
      color: "blackAlpha.800",
      _hover: {
        backgroundColor: "transparent",
      },
    },
    outlineLight: {
      border: "1px solid",
      borderColor: "gray.50",
      color: "gray.50",
      _hover: {
        backgroundColor: "transparent",
      },
    },
  },
});

const Heading = defineStyle({
  baseStyle: {
    textTransform: "uppercase",
    letterSpacing: "0.6rem",
    fontWeight: "bold",
    marginBottom: "6",
    sx: {
      "font-stretch": "151%",
      "font-variation-settings": `"YTUC" 400, "XTRA" 600`,
    },
  },
});

export default extendTheme({
  fonts: {
    heading: `"Roboto Flex", sans-serif`,
    body: `"Roboto Flex", sans-serif`,
  },
  colors: {
    transparent: "transparent",
    black: "#323232",
    white: "#e8e8e8",
    primary: "#7ed957",
  },
  components: {
    Button,
    Heading,
  },
});
