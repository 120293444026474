import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import Layout from "./components/Layout";
import theme from "./@chakra-ui/gatsby-plugin/theme";
import Fonts from "./styles/Fonts";

export const wrapPageElement = ({ element }) => {
  return (
    <ChakraProvider theme={theme} cssVarsRoot="body" resetCSS>
      <Fonts />
      <Layout>{element}</Layout>
    </ChakraProvider>
  );
};
